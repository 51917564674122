import React, {Component} from "react";
import {getAuth} from "firebase/auth";
import AdminNav from "../../components/AdminNav";
import GetClientTable from "../../functions/admin-functions/client-table";

class Clients extends Component{
    constructor(props){
        super(props);

        this.state = {
            showSession: true,
        }



    }

    handleClick = (event) => {
        this.setState({
            showSession: true
        });
    }


    render() {
        return (
            <div className="pageContainer">
                <AdminNav/>

                <div className="productContainer">
                    <div className="sweat-test-table-container">
                        <h3 className="module-title">Clients</h3>
                        <div className="sweatTestTable">
                            <GetClientTable/>
                        </div>
                    </div>
                    <div className="sweat-test-add-session">
                        <button onClick={this.handleClick}> Add Product</button>
                    </div>

                </div>

            </div>
        )

    }

}

export default Clients;