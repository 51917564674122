import React, { Component } from "react";
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getAuth } from 'firebase/auth';
import {doc, deleteDoc, onSnapshot, collection, getDocs, updateDoc, arrayUnion} from "firebase/firestore";
import { db } from '../../firebase/db.js';
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";


class GetClientTable extends Component {
    removeClient;
    constructor(props) {
        super(props);

        this.state={
            newData: [],
            prodType: '',
            loading: false,
            userData: []
        };


    }



    componentDidMount = async () => {

        const {userData} = this.state;



        const userDocs = await getDocs(collection(db, "users"));

        userDocs.forEach((doc) => {


                getAuth().getUser(doc.id).then(userRecord => {
                console.log(userRecord);
            })
                .catch((error) => {
                    console.log(error);
                })

            let userObj = {
                ['first_name']: doc.data().first_name,
                ['last_name']:doc.data().last_name,
                ['id']: doc.id,
            }

            this.state.userData.push(userObj)
        });

        this.setState({userData});

        console.log(userData);
        //querySnapshot.forEach((doc) => {
        //console.log(doc.id);

        //    let obj = {
        //        ['id']:doc.id,
        //        ['type']:doc.data().type,
        //        ['name']:doc.data().name,


        //    }

        //    newData.push(obj);

        //});

        this.removeClient = async (e) => {
            let deleteConfirm = window.confirm("Are you sure you want to delete this Client?");

        }

        $(document).ready(function () {
            setTimeout(function () {
                $('#sweatTable').DataTable();
            }, 1000);
        });

        //this.setState({newData});

        //}

    }


    render()
    {

        return (
            <div className="sweatTableContainer">

                <table id="sweatTable" className="sweat-table">
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Product Name
                        </th>



                        <th>
                            Remove
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    {this.state.userData.map((result) => {

                        //console.log(result);
                        return(
                            <tr key={result.id}>


                                <td><span className="inputHidden">{result.name}</span><input data-prodid={result.id} name="name" type="text" defaultValue={result.first_name + ' ' + result.last_name} onBlur={this.updateName}/></td>
                                <td><span className="inputHidden">{result.email}</span><input data-prodid={result.id} name="flavor" type="text" defaultValue={result.flavor} onBlur={this.updateFlavor}/></td>
                                <td><span className="inputHidden">{result.password}</span><input data-prodid={result.id} name="calories" type="text" defaultValue={result.calories} onBlur={this.updateCals}/></td>

                                <td onClick={(e) => this.removeClient(result.id, e)}><button className="remove-prod-button">Remove</button></td>

                            </tr>

                        )
                    })
                    }

                    </tbody>
                </table>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }
            </div>
        )

    }

}

export default GetClientTable;