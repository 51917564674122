import React, {Component} from 'react';
import {useSession} from '../../firebase/UserProvider';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {UserMetaForm} from "../../functions/user-functions/updateUserMeta";
import Navbar from "../../components/Navbar";
import {useSearchParams, useLocation, useParams} from 'react-router-dom';
import {addDoc, collection, getDocs, getDoc, updateDoc, doc, where, query, collectionGroup} from "firebase/firestore";
import { db } from "../../firebase/db";
import {Dropdown, Spinner} from "react-bootstrap";
import AddRacePlan from "../../functions/user-functions/addRacePlan";


function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RaceEdit extends Component{
    constructor(props) {
        super(props);

        this.race_name = React.createRef();
        this.race_time = React.createRef();
        this.race_date = React.createRef();
        this.race_humidity = React.createRef();
        this.race_temp = React.createRef();
        this.race_type = React.createRef();
        this.current_weight = React.createRef();
        this.run_est_time = React.createRef();
        this.bike_est_time = React.createRef();
        this.carb = React.createRef();
        this.fluid = React.createRef();
        this.sodium = React.createRef();
        this.carb_value = React.createRef();
        this.fluid_value = React.createRef();
        this.sodium_value = React.createRef();
        this.sodium_questions = React.createRef();
        this.start_race_type = '';

        this.state = {loading: false}
        this.state = {race_name: ''}
        this.state = {race_duration: ''}
        this.state = {race_temp: ''}
        this.state = {race_date: ''}
        this.state = {race_humidity: ''}
        this.state = {race_type: ''}
        this.state = {race_time:''}
        this.state = {race_start_time:''}
        this.state = {bike_est_time:''}
        this.state = {sodium_consume:''}
        this.state = {run:''}
        this.state = {id: ''}
        this.raceObject = {
            ['race_name']: '',
            ['est_time']: '',
            ['race_temp']: '',
            ['race_date']: '',
            ['race_humidity']: '',
            ['race_type']: '',
            ['timestamp']: '',
            ['run_est_time']: '',
            ['bike_est_time']: '',

        };

        this.updateRaceData = this.updateRaceData.bind(this);
        //this.updateRaceSubmit = this.updateRaceSubmit.bind(this);
        this.raceNameInput = this.raceNameInput.bind(this);
        this.raceTimeInput = this.raceTimeInput.bind(this);
        this.raceTempInput = this.raceTempInput.bind(this);
        this.raceDateInput = this.raceDateInput.bind(this);
        this.raceHumidityInput = this.raceHumidityInput.bind(this);
        this.raceTypeInput = this.raceTypeInput.bind(this);
        this.estBikeInput = this.estBikeInput.bind(this);
        this.estRunInput = this.estRunInput.bind(this);
        this.raceStartTime = this.raceStartTime.bind(this);
        this.startHideSelect = this.startHideSelect.bind(this);
        this.showHideSelect = this.showHideSelect.bind(this);


    }

    raceNameInput(event){
        this.raceObject.race_name = event.target.value

    }

    raceTimeInput(event){
        this.raceObject.race_time = event.target.value

    }
    raceTempInput(event){
        this.raceObject.race_temp = event.target.value

    }
    raceDateInput(event){
        this.raceObject.race_date = event.target.value

    }
    raceHumidityInput(event){
        this.raceObject.race_humidity = event.target.value

    }
    raceTypeInput(event){
        this.raceObject.race_type = event.target.value
        console.log(this.raceObject);
    }

    estBikeInput(event){
        console.log(event.target.value);
        console.log(this.bike_est_time.current.value);
        this.raceObject.bike_est_time = this.bike_est_time.current.value

    }

    estRunInput(event){
        console.log(event.target.value);
        console.log(this.bike_est_time.current.value);
        this.raceObject.run_est_time = this.run_est_time.current.value;

    }

    raceStartTime(event){
        console.log(event.target.value);
        console.log(this.race_start_time.current.value);
        this.raceObject.race_start_time = this.race_start_time.current.value;

    }

    componentDidMount = async () => {
        let {id} = this.props.params;
        this.fetchData(id);
    }
    fetchData = async id => {
        console.log(id);
        const auth = getAuth();
        const user = auth.currentUser;

        // The user object has basic properties such as display name, email, etc.
        const
            displayName = user.displayName;
        const
            email = user.email;
        const
            photoURL = user.photoURL;
        const
            emailVerified = user.emailVerified;
        const
            user_id = user.uid;

        const docSnap = await getDocs(collection(db, "users", user_id, 'race-plan'));
        docSnap.forEach((doc) => {
            if (id === doc.id) {

                this.setState({id:doc.id});
                this.setState({race_name:doc.data().race_name});
                this.setState({race_time:doc.data().race_time});
                this.setState({race_temp:doc.data().race_temp});
                this.setState({race_humidity:doc.data().race_humidity});
                this.setState({race_date:doc.data().race_date});
                this.setState({race_start_time:doc.data().race_start_time})
                this.setState({race_type:doc.data().race_type});
                this.setState({bike_est_time:doc.data().bike_est_time});
                this.setState({run_est_time:doc.data().run_est_time});
                this.setState({sodium_consume:doc.data().sodium_consume});
                this.setState({sodium_value:doc.data().sodium_value});
                this.setState({carb_value:doc.data().carb_value});
                this.setState({carb_consume:doc.data().carb_consume});
                this.setState({sodium_questions:doc.data().sodium_question});
                this.setState({fluid_consume:doc.data().fluid_consume});
                this.setState({fluid_value:doc.data().fluid_value})
                this.setState({current_weight:doc.data().current_weight});
                this.start_race_type = doc.data().race_type;


                this.sodium_consume = doc.data().sodium_consume;
                this.carb_consume = doc.data().carb_consume;
                this.fluid_consume = doc.data().fluid_consume;

                this.view_url = "/client-dashboard/race-plan/details/" + doc.id;
            }
        });


        this.startHideSelect();

    }

    showHideSelect = (e) => {

        this.setState({race_type:this.race_type.current.value});

        let sodium = this.sodium.current.value;
        let sodium_questions = this.sodium_questions.current.value;

        this.setState({sodium_questions: sodium_questions});

        if (sodium == 'yes') {
            this.setState({showSodium: 'show-sodium'});
            this.setState({showQuestions: 'hide-questions'});
        } else {
            this.setState({showSodium: 'hide-sodium'});
            this.setState({showQuestions: 'show-questions'});
        }

        this.setState({sodium_consume:sodium});


        let carbs = this.carb.current.value;


        if(carbs == 'yes'){
            this.setState({showCarbs:'show-carbs'});

        }else{
            this.setState({showCarbs:'hide-carbs'});
        }

        this.setState({carb_consume:carbs});


        let fluids = this.fluid.current.value;


        if(fluids == 'yes'){
            this.setState({showFluids:'show-fluids'});

        }else{
            this.setState({showFluids:'hide-fluids'});
        }

        this.setState({fluid_consume:fluids});

        //this.setState({questions:this.questions.current.value});


        if(this.race_type.current.value === 'single-sport'){
            this.setState({show_est:'hide-est'})
        }else{
            this.setState({show_est:'show-est'})
        }

    }

    startHideSelect = () => {

        this.setState({showEst:'hide-est'})

        let sodium = this.sodium_consume;
        let carbs = this.carb_consume;
        let fluids = this.fluid_consume;


        if (sodium === 'yes') {
            this.setState({showSodium: 'show-sodium'});
            this.setState({showQuestions: 'hide-questions'});

        } else {
            this.setState({showSodium: 'hide-sodium'});
            this.setState({showQuestions: 'show-questions'});
        }


        if(carbs === 'yes'){
            this.setState({showCarbs:'show-carbs'});

        }else{
            this.setState({showCarbs:'hide-carbs'});
        }

        //this.setState({carb_consume:carbs});


        if(fluids === 'yes'){
            this.setState({showFluids:'show-fluids'});

        }else{
            this.setState({showFluids:'hide-fluids'});
        }

        console.log(this.race_type.current.value);

        if(this.start_race_type === 'single-sport'){
            this.setState({show_est:'hide-est'})
        }else{
            this.setState({show_est:'show-est'})
        }

    }


    changeType = () => {
        this.setState({race_type:this.race_type.current.value})
    }


    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };

    updateRaceData = async (e) => {
        e.preventDefault();
        let {id} = this.props.params;
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;

        let race_hours = Math.ceil(this.race_time.current.value / 60);
        let bike_hours = Math.ceil(this.bike_est_time.current.value / 60);
        let run_hours = Math.ceil(this.run_est_time.current.value / 60);

        let runArray = [];
        let bikeArray = [];

        let race_start_time = 0;

        console.log(this.state.race_type);

        if(this.state.race_type === 'triathlon') {

            for (let i = 0; i < bike_hours; i++) {
                let race_label = (race_start_time + i) + ' - ' + (race_start_time + i + 1);

                let tempObj = {
                    time: race_label,
                }

                bikeArray.push(race_label);

            }



            for(let i = 0; i < run_hours; i++){
                let race_label = ((race_start_time + i) + ' - ' + (race_start_time + i+1))

                let tempObj = {
                    time: race_label
                }

                runArray.push(race_label);
            }
        }else{
            run_hours = this.race_time.current.value/60;
            for(let i = 0; i < run_hours; i++){
                let race_label = ((race_start_time + i) + ' - ' + (race_start_time + i+1))


                runArray.push(race_label);
            }
        }

        let sodium_consume = this.sodium.current.value;

        let sodium_questions = this.sodium_questions.current.value;

        let sodium_value = this.sodium_value.current.value;

        let carb_consume = this.carb.current.value;

        let carb_value = this.carb_value.current.value;

        let fluid_consume = this.fluid.current.value;

        let fluid_value = this.fluid_value.current.value;

        let current_weight = this.current_weight.current.value;

        let update_confirm = window.confirm('Warning:  Updating Estimated Race Duration, Estimated Bike Time ' +
            'or Estimated Run Time will delete all current products in your Race Plan');

        if (update_confirm === true) {

            const getRace = await getDoc(doc(db, 'users', userID, 'race-plan', id));

            // If the time changes remove products or they will not work properly.

            if(
                getRace.data().bike_est_time != this.bike_est_time.current.value
                || getRace.data().run_est_time != this.run_est_time.current.value
                || getRace.data().race_time != this.race_time.current.value
                || getRace.data().race_type != this.race_type.current.value
                || getRace.data().race_type != this.race_type.current.value
            ){

                console.log('remove-products');
                await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
                    bike:{products:[]},
                    run:{products:[]}
                })
            }


                // get number of weeks
                let todays_date = Math.floor(Date.now() / 1000);
                let race_date = Date.parse(this.race_date.current.value) / 1000;


                let seconds_until_race = race_date - todays_date;

                let minutes_to_race = seconds_until_race/60;
                let hours_to_race = minutes_to_race/60;
                let days_to_race = hours_to_race/24;
                this.weeks_until_race = Math.round(days_to_race/7);



            await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
                ['timestamp']: Date.now(),
                ['race_name']: this.race_name.current.value,
                ['race_time']: this.race_time.current.value,
                ['race_temp']: this.race_temp.current.value,
                ['race_date']: this.race_date.current.value,
                ['race_humidity']: this.race_humidity.current.value,
                ['race_type']: this.race_type.current.value,
                ['run_est_time']: this.run_est_time.current.value,
                ['bike_est_time']: this.bike_est_time.current.value,
                ['sodium_consume']: sodium_consume,
                ['sodium_value']: sodium_value,
                ['sodium_question']: sodium_questions,
                ['carb_consume']: carb_consume,
                ['carb_value']: carb_value,
                ['fluid_consume']: fluid_consume,
                ['fluid_value']: fluid_value,
                ['current_weight']: current_weight,
                ['bike_time_slots']: bikeArray,
                ['run_time_slots']: runArray,
                ['weeks_until_race']:this.weeks_until_race

            })

            //TODO ON RACE ADD, ADD JUST ID TO GUT TRAINING AND PULL RACE DETAILS FROM ACTUAL RACE DATA.
            const gutTraining = await getDocs(collection(db, 'users', userID, 'gut-training'));

            let doc_id_array = [];

            gutTraining.forEach((doc) => {

                let race_id = doc.data().race_details.id;

                if(id == race_id)
                {
                    doc_id_array.push(doc.id, race_id);
                }
            })



            const docRef = doc(db, 'users', userID, 'gut-training', doc_id_array[0]);

            await updateDoc(docRef, {
                ['weeks_until_race']: this.weeks_until_race,
                ['race_details']: {
                    id: doc_id_array[1],
                    race_name: this.race_name.current.value,
                    date: this.race_date.current.value,
                    race_temp: this.race_temp.current.value,
                    race_type: this.race_type.current.value,
                    race_humidity: this.race_humidity.current.value,
                    time: this.race_time.current.value,
                }

            }).then(() => {
                console.log('works');
                window.location.reload(false);
            })


        }
    }


    render(){
    return (
        <div className="pageContainer">
            <Navbar/>


            <div className="gutSingleContainer">
                <div className="gut-training-single-single row">
                    <div className="row">
                        <div className="col">
                            <h3 className="module-title">Race Name - {this.state.race_name}</h3>
                        </div>
                        <div className="col">
                            <a href={this.view_url}>Race Details </a>
                        </div>
                    </div>


                    <div className="row">

                        <div className="racePlan">

                        <form onSubmit={this.updateRaceData}>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="race-name">Race Name</label><br/>
                                                    <input name="race-name" type="text"
                                                           defaultValue={this.state.race_name} ref={this.race_name}/>
                                                    <label htmlFor="est-race-time">Estimated Race Duration
                                                        (min) </label><br/>
                                                    <input name="est-race-time" type="text"
                                                           defaultValue={this.state.race_time} ref={this.race_time}/>
                                                    <label htmlFor="race-temp">Race Temperature (F)</label><br/>
                                                    <input name="race-temp" type="text"
                                                           defaultValue={this.state.race_temp}
                                                           ref={this.race_temp}/><br/>
                                                </div>
                                                <div className="col">

                                                    <label htmlFor="race-date">Race Date</label><br/>
                                                    <input name="race-date" type="date" placeholder="dd-mm-yyyy"
                                                           defaultValue={this.state.race_date} ref={this.race_date}/>
                                                    <label htmlFor="race-humidity">Race Humidity (%)</label><br/>
                                                    <input name="race-humidity" type="text"
                                                           defaultValue={this.state.race_humidity}
                                                           ref={this.race_humidity}/> <br/>
                                                    <label htmlFor="race-weight">Current Weight</label><br/>
                                                    <input name="race-weight" type="text"
                                                           defaultValue={this.state.current_weight}
                                                           ref={this.current_weight}/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="race-type">Type of Race</label><br/>
                                                    <select name="race-type" value={this.state.race_type}
                                                            ref={this.race_type} onChange={this.showHideSelect}>
                                                        <option value="triathlon">Triathlon</option>
                                                        <option value="single-sport">Single Sport</option>
                                                    </select>
                                                    <div className={this.state.show_est}>
                                                    <label htmlFor="race-est-bike">Estimated Bike Time</label>
                                                    <input type="text" name="race-est-bike"
                                                           defaultValue={this.state.bike_est_time}
                                                           ref={this.bike_est_time}/>
                                                    </div>
                                                    <div className={this.state.show_est}>
                                                    <label htmlFor="race-est-run">Estimated Run Time</label>
                                                    <input type="text" name="race-est-run"
                                                           defaultValue={this.state.run_est_time}
                                                           ref={this.run_est_time}/>
                                                    </div>
                                                </div>
                                                <p>&nbsp;</p>

                                                <div className="row">


                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4">
                                                        <label htmlFor="sodium-loss">Have you tested sodium
                                                            loss?</label>
                                                        <select name="sodium-loss" value={this.state.sodium_consume} ref={this.sodium}
                                                                onChange={this.showHideSelect}>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>

                                                        <br/>

                                                        <div className={this.state.showSodium}>
                                                            <label htmlFor="sodium-loss-value">Sodium Loss Value
                                                                (mg/oz)</label><br/>
                                                            <input name="sodium-loss-value" type="text" defaultValue={this.state.sodium_value}
                                                                   ref={this.sodium_value}/>
                                                        </div>

                                                        <div className={this.state.showQuestions}>
                                                            <label htmlFor="sodium-question">Do you?</label><br/>
                                                            <select name="sodium-question" ref={this.sodium_questions}
                                                                    value={this.state.sodium_questions}
                                                                        onChange={this.showHideSelect}>
                                                                <option value="0">Select Your Answer</option>
                                                                <option value="a">Rarely notice salt on my clothes,
                                                                    rarely
                                                                    taste salty or crave
                                                                    salty foods
                                                                </option>
                                                                <option value="b">Occasionally notice salt on my clothes
                                                                    after more intense or
                                                                    longer effects
                                                                </option>
                                                                <option value="c">Almost always see white marks on my
                                                                    clothes after training and
                                                                    regularly crave salty foods
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <br/>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">

                                                        <div>
                                                            <label htmlFor="carb-consumed">Do you currently consume
                                                                carbohydrates during
                                                                training?</label><br/>
                                                            <select name="carb-consumed" value={this.state.carb_consume}
                                                                    ref={this.carb}
                                                                    onChange={this.showHideSelect}>
                                                                <option value="yes">Yes</option>
                                                                <option value="no">No</option>
                                                            </select>

                                                        </div>
                                                        <br/>

                                                        <div className={this.state.showCarbs}>
                                                            <label htmlFor="carb-consumed-value">How many carbs/hr?
                                                                (g)</label><br/>
                                                            <input name="carb-consumed-value" type="text"
                                                                   defaultValue={this.state.carb_value}
                                                                   ref={this.carb_value}/>
                                                        </div>
                                                        <br/>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <label htmlFor="fluid-consume">Do you currently consume fluids
                                                            during
                                                            training</label><br/>
                                                        <select name="fluid-consume" value={this.state.fluid_consume}
                                                                ref={this.fluid}
                                                                onChange={this.showHideSelect}>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>

                                                        <br/>
                                                        <div className={this.state.showFluids}>
                                                            <label htmlFor="fluids-value">How many fluids/hr?
                                                                (oz)</label><br/>
                                                            <input name="fluids-value" type="text"
                                                                   defaultValue={this.state.fluid_value}
                                                                   ref={this.fluid_value}/>
                                                        </div>
                                                        <br/>
                                                    </div>
                                                </div>
                                                <button onClick={() => this.toggleLoader()}
                                                        className="new-session-submit">Update Race Plan
                                                </button>

                                            </div>
                                        </form>




                                    </div>
                                </div>
                </div>
            </div>


        </div>
    );
    }


}

export default withParams(RaceEdit);
