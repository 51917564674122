import React, { Component } from 'react';
import { useForm } from 'react-hook-form';
import {useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { db } from "../firebase/db";
import {doc, addDoc, collection, setDoc} from "firebase/firestore";
import { signup } from '../firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faDownLong, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

function withNavigation(Component : Component){
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
            emailValue: '',
            emailConfirmValue: '',
            firstNameValue: '',
            lastNameValue: '',
            passwordValue: '',
            passwordConfirmValue: '',
            passwordInput: 'password',
            passwordInputID: 'password-view',
            passwordConfirmInput: 'password',
            passwordConfirmInputID: 'password-view',
            eyeIcon: <FontAwesomeIcon icon={faEye}/>,
            confirmEyeIcon: <FontAwesomeIcon icon={faEye}/>,
            user_error: ''
        }



        this.first_name = React.createRef();
        this.last_name = React.createRef();
        this.email = React.createRef();
        this.confirm_email = React.createRef();
        this.password = React.createRef();
        this.confirm_password = React.createRef();
        this.privacy = React.createRef();


        this.onSubmit = this.onSubmit.bind(this);
        this.passwordToggle = this.passwordToggle.bind(this);
        this.emailValueInput = this.emailValueInput.bind(this);
        this.emailConfirmValueInput = this.emailConfirmValueInput.bind(this);
        this.firstNameValueInput = this.firstNameValueInput.bind(this);
        this.lastNameValueInput = this.lastNameValueInput.bind(this);
        this.passwordValueInput = this.passwordValueInput.bind(this);
        this.passwordConfirmValueInput = this.passwordConfirmValueInput.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);

    }

    emailValueInput(event){
        this.setState({emailValue:event.target.value});
    }

    emailConfirmValueInput(event){
        this.setState({emailConfirmValue:event.target.value});
    }

    passwordValueInput(event){
        this.setState({passwordValue:event.target.value});
    }
    passwordConfirmValueInput(event){
        this.setState({passwordConfirmValue:event.target.value});
    }
    firstNameValueInput(event){
        this.setState({firstNameValue:event.target.value});
    }
    lastNameValueInput(event) {
        this.setState({lastNameValue: event.target.value});
    }
    onCheckChange(event) {
        console.log(event.target.checked);
        this.setState({isChecked: true});
    }

    onSubmit = async (e) => {
        e.preventDefault();
        let first_name = this.state.firstNameValue;
        let last_name = this.state.lastNameValue;
        let email = this.state.emailValue;
        let password = this.state.passwordValue;
        let confirm_password = this.state.passwordConfirmValue;
        let confirm_email = this.state.emailConfirmValue;
        let privacy = this.state.isChecked;


        if(email === confirm_email){
            if(password === confirm_password) {
                try {

                    let userData = {
                        'first_name': first_name,
                        'last_name': last_name,
                        'email': email,
                    };

                    let signupData = {
                        'email': email,
                        'password': password,
                    }

                    if (privacy === true) {
                        //setChecked('');
                        let add_user = await signup(signupData);
                        await setDoc(doc(db, 'users', add_user.uid), userData);
                        this.setState({ redirectTo: '/' });
                    } else {
                        this.setState({user_error:'You need to agree to terms to register'});
                    }

                    //reset();
                } catch (error) {
                    console.log(error);
                }
            }else{
                // else password confirm
                this.setState({user_error:'Passwords do not match!'});
            }
        }else{
            //else email confirm
            this.setState({user_error:'Emails do not match!'});
        }
        //setLoading(false);
    }


    passwordToggle = (e) => {
        let current_view = e.currentTarget.id;
        let pass_class = e.currentTarget.className;
        console.log(current_view);
        if (current_view === 'password-view') {
            this.setState({passwordInputID: 'text-view'});
            this.setState({passwordInput: 'text'});
            this.setState({eyeIcon: <FontAwesomeIcon icon={faEyeSlash}/>});
        } else {
            this.setState({passwordInputID: 'password-view'});
            this.setState({passwordInput: 'password'});
            this.setState({eyeIcon: <FontAwesomeIcon icon={faEye}/>});
        }
    }

    confirmPasswordToggle = (e) => {
        let current_view = e.currentTarget.id;
        let pass_class = e.currentTarget.className;
        if (current_view === 'password-view') {
            this.setState({passwordConfirmInputID: 'text-view'});
            this.setState({passwordConfirmInput: 'text'});
            this.setState({confirmEyeIcon: <FontAwesomeIcon icon={faEyeSlash}/>});
        } else {
            this.setState({passwordConfirmInputID: 'password-view'});
            this.setState({passwordConfirmInput: 'password'});
            this.setState({confirmEyeIcon: <FontAwesomeIcon icon={faEye}/>});
        }
    }


render() {
    if (this.state.redirectTo) {
        return <Navigate to={"/"} />;
    }
    return (
        <div className="pageContainer">
            <div className="signup-container">
                <h2>Signup</h2>
                <form className="login-fields" onSubmit={this.onSubmit}>
                    <br/>
                    <label>First Name</label><br/>
                    <input type="text" name="firstName" value={this.state.firstNameValue} onChange={this.firstNameValueInput}/><br/><br/>
                    <label>Last Name</label><br/>
                    <input type="text" name="lastName" value={this.state.lastNameValue} onChange={this.lastNameValueInput}/><br/><br/>
                    <label>Username/Email</label>
                    <input type="email" name="email" value={this.state.emailValue} onChange={this.emailValueInput}/> <br/><br/>
                    <label>Confirm Username/Email</label><br/>
                    <input type="email" name="email-confirm" value={this.state.emailConfirmValue} onChange={this.emailConfirmValueInput}/> <br/><br/>
                    <div className="password-toggle">
                        <label htmlFor="password">Password</label><br/>
                        <input id="password" type={this.state.passwordInput} name="password" value={this.state.passwordValueInput} onChange={this.passwordValueInput}/>
                        <span className="password-toggle-icon" id={this.state.passwordInputID}
                              onClick={this.passwordToggle}>{this.state.eyeIcon}</span>
                    </div>
                    <div className="password-toggle">
                        <label htmlFor="confirm-password">Confirm Password</label><br/>
                        <input id="confirm-password" type={this.state.passwordConfirmInput} name="confirm-password" value={this.state.passwordConfirmValue} onChange={this.passwordConfirmValueInput}/>
                        <span className="password-toggle-icon" id={this.state.passwordConfirmInputID}
                              onClick={this.confirmPasswordToggle}>{this.state.confirmEyeIcon}</span>
                    </div><br/>
                    <label htmlFor="privacy">I agree to the <a href="/terms/" target="_blank">Terms &
                        Conditions</a></label><br/>
                    <input type="checkbox" name="privacy" onChange={this.onCheckChange}/> <br/>
                    <p className="error-text">{this.state.isChecked}</p>
                    <button className="signup-button" type="submit">Signup</button>
                </form>
                <div className="error-output">{this.state.user_error}</div>

            </div>
        </div>
    );
}

}


export default Signup;