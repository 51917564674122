import logo from './logo.svg';
import './App.css';
import './firebase/config.js';
import Signup from './pages/Signup';
import Login from './pages/Login.js';
import Reset from './pages/Reset.js';
import ClientDash from './pages/client-dashboard/client-dashboard.js';
import AdminDash from './pages/practitioner-dashboard/practitioner-dashboard.js';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Account from './pages/client-dashboard/Account.js';
import { ProtectedRoute } from "./ProtectedRoutes";
import {useAuth} from "./firebase/useAuth";
import { Header } from './Header';
import { Footer } from './Footer';
import Sweat from './pages/client-dashboard/sweat';
import Gut from './pages/client-dashboard/gut-training';
import Race from './pages/client-dashboard/race-plans';
import { AuthProvider } from './firebase/UserProvider.js';
import Products from './pages/practitioner-dashboard/products';
import Resources from './pages/practitioner-dashboard/resources';
import GutDetails from './pages/client-dashboard/gut-training-single-details';
import GutDetailsCompleted from './pages/client-dashboard/gut-training-completed-details';
import GutCompleted from './pages/client-dashboard/gut-completed';
import GutEdit from './pages/client-dashboard/gut-training-single-edit';
import RaceEdit from './pages/client-dashboard/race-plan-edit';
import RaceDetails from './pages/client-dashboard/race-plan-details';
import Privacy from './pages/privacy-policy';
import Terms from './pages/terms';
import Clients from './pages/practitioner-dashboard/clients';
import Suggest from "./pages/client-dashboard/suggest-product";


function App() {

  return (
        <AuthProvider>
          <Router>
            <Header />
                <div className="app-body">
                </div>

                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/signup/" element={<Signup />} />
                    <Route path="/reset/" element={<Reset />} />
                    <Route path="/privacy-policy/" element={<Privacy />} />
                    <Route path="/terms/" element={<Terms />} />
                  <Route path="/client-dashboard/" element={<ProtectedRoute><ClientDash /></ProtectedRoute>} />
                    <Route path="/client-dashboard/suggest-product/" element={<ProtectedRoute><Suggest /></ProtectedRoute>} />
                    <Route path="/client-dashboard/account/" element={<ProtectedRoute><Account /></ProtectedRoute>} />
                    <Route path="/client-dashboard/sweat-testing/" element={<ProtectedRoute><Sweat /></ProtectedRoute>} />
                    <Route path="/client-dashboard/gut-training/" element={<ProtectedRoute><Gut /></ProtectedRoute>} />
                    <Route path="/client-dashboard/gut-training-completed/" element={<ProtectedRoute><GutCompleted /></ProtectedRoute>} />
                    <Route path="/client-dashboard/gut-training/details/:id" element={<ProtectedRoute><GutDetails /></ProtectedRoute>} />
                    <Route path="/client-dashboard/gut-training/completed-details/:id" element={<ProtectedRoute><GutDetailsCompleted /></ProtectedRoute>} />
                    <Route path="/client-dashboard/gut-training/edit/:id" element={<ProtectedRoute><GutEdit /></ProtectedRoute>} />
                    <Route path="/client-dashboard/race-plan/" element={<ProtectedRoute><Race /></ProtectedRoute>} />
                    <Route path="/client-dashboard/race-plan/edit/:id" element={<ProtectedRoute><RaceEdit /></ProtectedRoute>} />
                    <Route path="/client-dashboard/race-plan/details/:id" element={<ProtectedRoute><RaceDetails /></ProtectedRoute>} />
                <Route path="/practitioner-dashboard/" element={<ProtectedRoute><AdminDash /></ProtectedRoute>} />
                    <Route path="/practitioner-dashboard/products/add-product" element={<ProtectedRoute><Products /></ProtectedRoute>} />
                    <Route path="/practitioner-dashboard/resources/" element={<ProtectedRoute><Resources /></ProtectedRoute>} />
                    <Route path="/practitioner-dashboard/clients/client-list" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
                </Routes>
            <Footer />
        </Router>
        </AuthProvider>

  );
}



export default App;
