import React, {Component} from 'react';
import {useSession} from '../../firebase/UserProvider';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Navbar from "../../components/Navbar";
import {useParams} from 'react-router-dom';
import {addDoc, collection, getDocs, getDoc, updateDoc, doc, arrayUnion, arrayRemove, deleteField} from "firebase/firestore";
import { db } from "../../firebase/db";
import {Dropdown, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';



function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class GutDetails extends Component{
    constructor(props){
        super(props);
        this.carb = React.createRef();
        this.fluid = React.createRef();
        this.sodium = React.createRef();
        this.carb_value = React.createRef();
        this.fluid_value = React.createRef();
        this.sodium_value = React.createRef();
        this.prodIndex = React.createRef();
        this.carb_new = '';
        this.fluid_new = '';
        this.sodium_new = '';
        this.carb_value_new = '';
        this.fluid_value_new = '';
        this.sodium_value_new = '';
        this.fuel = React.createRef();
        this.hydration = React.createRef();
        this.questions = React.createRef();
        this.gutid = '';
        this.sodium_loss = '';
        this.sodium_consume = '';


        this.productID = '';
        this.state = {value: ''};
        this.state = {loading: false}
        this.state = {
            title: '',
            date: '',
            race_date: '',
            sodium_consume:'',
            sodium_loss_new:'',
            sodium_loss_value:'',
            carb_consume:'',
            carb_consume_value: '',
            fluid_consume: '',
            fluid_value: '',
            product: '',
            products: [],
            current_products:[],
            productInfo:[],
            showSodium: '',
            showCarbs: '',
            showFluids: '',
            questions: '',

        }


        this.addGutSubmit = this.addGutSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.productInput = this.productInput.bind(this);
        this.addFueling = this.addFueling.bind(this);
        this.addHydration = this.addHydration.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.componentDidMount = this.componentDidMount();
        this.showHideSelect = this.showHideSelect.bind(this);

    }

    componentDidMount = async () => {
        let {id} = this.props.params;
        await this.fetchData(id);
        this.gutid = id;
        this.startHideSelect(id);
    }
    fetchData = async id => {
        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;

        const docSnap = await getDoc(doc(db, "users", user_id, 'gut-training', id));

        let race_id = docSnap.data().race_details.id;

        const raceSnap = await getDoc(doc(db, "users", user_id, 'race-plan', race_id));
            //if (id == docSnap.id)

                console.log(docSnap.data().carb_consume);

                this.setState({title: raceSnap.data().race_name});
                this.setState({date: docSnap.data().race_details.race_date});
                this.setState({carb_consume: docSnap.data().carb_consume});
                this.setState({sodium_consume: docSnap.data().sodium_consume});
                this.setState({sodium_loss_value: Number(docSnap.data().sodium_loss_value)});
                this.setState({carb_consume_value: Number(docSnap.data().carb_consume_value)});
                this.setState({fluid_value: Number(docSnap.data().fluid_value)});
                this.setState({fluid_consume: docSnap.data().fluid_consume});



                let currentProd = docSnap.data().products;


        // initialize product state
        const {productInfo} = this.state;

        // get all products from firebase
        const prodSnap = await getDocs(collection(db, "products"));

        // loop through products
        prodSnap.forEach((doc) => {
            let obj = {
                ['id']:doc.id,
                ['product_name']: doc.data().name,
                ['type']: doc.data().type,
                ['product_flavor']: doc.data().flavor,
                ['carbs']: doc.data().carbs
            }

            this.state.productInfo.push(obj);
        });

        //sort products
        this.state.productInfo.sort((a,b) => (a.product_name > b.product_name) ? 1 : ((b.product_name > a.product_name) ? -1 : 0));

        // set state all products and info
        this.setState({productInfo});

        // initialize state for products that are currently in this gut training
        const {current_products} = this.state;

        // loop through users gut training with parameter ID
        const gutSnap = await getDoc(doc(db, "users", user_id, 'gut-training', id));


        // loop through each gut training array of products
            Object.values(gutSnap.data().products).forEach((doc) => {

                // set current products into temp object
                let prodObject = {
                        ['id']: doc.id,
                        ['name']: doc.name,
                        ['calories']: doc.calories,
                        ['carbs']: parseInt(doc.carbs),
                        ['sodium']: doc.sodium,
                        ['serving']: doc.serving,
                        ['type']: doc.type,
                        ['flavor']: doc.flavor,
                    }

                // push object into state array
                this.state.current_products.push(prodObject);

            });
            // set state of product objects
            this.setState({current_products});



    }

    // onchange of input set the target key and target value
    onChange(e){
        //this.setState({[e.target.name]: e.target.value});

    }

    // this sets the products into gutObject state (not sure if we need this)
    productInput = async(event) =>{
        let prod_id = event.target.value
        const prodSnap = await getDoc(doc(db, "products", prod_id));

        this.gutObject.products = {
            ['id']:prodSnap.id,
            ['name']:prodSnap.data().name,
            ['caff']:prodSnap.data().caff,
            ['calories']:prodSnap.data().calories,
            ['carbs']:prodSnap.data().carbs,
            ['fiber']:prodSnap.data().fiber,
            ['flavor']:prodSnap.data().flavor,
            ['sodium']:prodSnap.data().sodium,
            ['type']:prodSnap.data().type,
        }


    }

    // update gut training on update click... data with data from gut training form
    addGutSubmit = async (e) => {
        e.preventDefault();

        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        let carb = this.carb.current.value;

        // if current value is undefined then use the defaultValue (which comes in from firebase database)
        if(carb === undefined){
            this.carb_new = this.carb.current.defaultValue;
        }else{
            this.carb_new = carb;
        }
        //console.log(this.carb_new);

        let sodium = this.sodium.current.value;

        if(sodium === undefined){
            this.sodium_new = this.sodium.current.defaultValue;
        }else{
            this.sodium_new = sodium
        }


        let fluid = this.fluid.current.value;

        if(fluid === undefined){
            this.fluid_new = this.fluid.current.defaultValue;
        }else{
            this.fluid_new = fluid;
        }

        let carb_value = this.carb_value.current.value;
        if(carb_value === undefined){
            this.carb_value_new = this.carb_value.current.defaultValue;
        }else{
            this.carb_value_new = carb_value;
        }

        let sodium_value = this.sodium_value.current.value;
        if(sodium_value === undefined){
            this.sodium_value_new = this.sodium_value.current.defaultValue;
        }else{
            this.sodium_value_new = sodium_value;
        }

        let fluid_value = this.fluid_value.current.value;

        if(fluid_value === undefined){
            this.fluid_value_new = this.fluid_value.current.defaultValue;
        }else{
            this.fluid_value_new = fluid_value;
        }


        // update the gut training per param ID
        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            sodium_consume: this.sodium_new,
            sodium_loss_value: this.sodium_value_new,
            carb_consume: this.carb_new,
            carb_consume_value:this.carb_value_new,
            fluid_consume:this.fluid_new,
            fluid_value:this.fluid_value_new,
            sodium_question:this.questions.current.value,
        }).then(() => {

            console.log('updated');

            }

        );

        // reload the page to show new data
        window.location.reload(false);


    }


    // update hydration products
    addHydration = async (e) => {

        e.preventDefault();
        let prod_id = this.hydration.current.value;
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;


        const prodSnap = await getDoc(doc(db, "products", prod_id));

        let updateHydObj = {
            ['id']: prodSnap.id,
            ['carbs']: prodSnap.data().carbs,
            ['caff']: prodSnap.data().caff,
            ['calories']: prodSnap.data().calories,
            ['fiber']: prodSnap.data().fiber,
            ['flavor']:prodSnap.data().flavor,
            ['name']:prodSnap.data().name,
            ['sodium']:prodSnap.data().sodium,
            ['type']:prodSnap.data().type,
            ['weekly_serving']:{week_one:0, week_two:0, week_three:0 , week_four:0, week_five:0, week_six:0, week_seven:0, week_eight:0, week_nine:0, week_ten:0},
            ['serving']:0,
            ['serving_size']:prodSnap.data().serving_size
        }


        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            ['products']: arrayUnion(updateHydObj)
        })

        window.location.reload(false);

    }
    // update fueling object
    addFueling = async (e) => {
        e.preventDefault();
        let prod_id = this.fuel.current.value;
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        const prodSnap = await getDoc(doc(db, "products", prod_id));

        let updateFuelObj = {
                ['id']: prodSnap.id,
                ['carbs']: prodSnap.data().carbs,
                ['caff']: prodSnap.data().caff,
                ['calories']: prodSnap.data().calories,
                ['fiber']: prodSnap.data().fiber,
                ['flavor']:prodSnap.data().flavor,
                ['name']:prodSnap.data().name,
                ['sodium']:prodSnap.data().sodium,
                ['type']:prodSnap.data().type,
                ['weekly_serving']:{week_one:0, week_two:0, week_three:0 , week_four:0, week_five:0, week_six:0, week_seven:0, week_eight:0, week_nine:0, week_ten:0},
                ['serving']:0,
                ['serving_size']:prodSnap.data().serving_size
            }



        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            ['products']: arrayUnion(updateFuelObj)
        })

        window.location.reload(false);

    }
    // sort product array to get in correct week order
    compare( a, b ) {
        if ( a.product_name < b.product_name ){
            return -1;
        }
        if ( a.product_name > b.product_name ){
            return 1;
        }
        return 0;
    }
    // function to remove a product from fueling or hydration products
    removeProduct = async (e) =>{
        e.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        let product_id = e.target.id;

        let product_index = this.state.current_products.findIndex(x => x.id === product_id);

        this.state.current_products.splice(product_index, 1);

        console.log(product_index);

        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            ['products']: this.state.current_products
        })

        window.location.reload(false);

    }

    // not currently using this function
    updateGutData = async (e) => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;
        console.log(id);


        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            sodium_consume: this.sodium_new,
            sodium_loss_value: this.sodium_value_new,
            carb_consume: this.carb_new,
            carb_consume_value: this.carb_value_new,
            fluid_consume: this.fluid_new,
            fluids_value: this.fluid_value_new

        })


    }

    // toggle to show loading gif (needs to be updated... not working correctly)
    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };

    // show hide per selected questions
    showHideSelect = (e) => {

        let sodium = this.sodium.current.value;

        if (sodium == 'yes') {
            this.setState({showSodium: 'show-sodium'});
            this.setState({showQuestions: 'hide-questions'});
        } else {
            this.setState({showSodium: 'hide-sodium'});
            this.setState({showQuestions: 'show-questions'});
        }

        this.setState({sodium_consume:sodium});


        let carbs = this.carb.current.value;


        if(carbs == 'yes'){
            this.setState({showCarbs:'show-carbs'});

        }else{
            this.setState({showCarbs:'hide-carbs'});
        }

        this.setState({carb_consume:carbs});


        let fluids = this.fluid.current.value;


        if(fluids == 'yes'){
            this.setState({showFluids:'show-fluids'});

        }else{
            this.setState({showFluids:'hide-fluids'});
        }

        this.setState({fluid_consume:fluids});

        this.setState({questions:this.questions.current.value});

    }

    // show hide per selected questions
    startHideSelect = async id => {

        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;

        const docSnap = await getDoc(doc(db, "users", user_id, 'gut-training', id));

        let sodium = docSnap.data().sodium_consume;


        if (sodium == 'yes') {
            this.setState({showSodium: 'show-sodium'});
            this.setState({showQuestions: 'hide-questions'});

        } else {
            this.setState({showSodium: 'hide-sodium'});
            this.setState({showQuestions: 'show-questions'});
        }


        let carbs = docSnap.data().carb_consume;

        if(carbs == 'yes'){
            this.setState({showCarbs:'show-carbs'});

        }else{
            this.setState({showCarbs:'hide-carbs'});
        }

        //this.setState({carb_consume:carbs});


        let fluids = docSnap.data().fluid_consume;


        if(fluids == 'yes'){
            this.setState({showFluids:'show-fluids'});

        }else{
            this.setState({showFluids:'hide-fluids'});
        }

        //this.setState({fluid_consume:fluids});

        this.setState({questions:docSnap.data().sodium_question});

    }




    // Render to the page
    render(){
            console.log(this.state.sodium_consume);
        return (
            <div className="pageContainer">
                <Navbar/>
            <div className="myRacePlanContainer">
                <div className="race-plan-container">
            <div className="gutForm">
                <h1>{this.state.title}</h1>
                <h3>{this.state.race_date}</h3>

                <span className="gut-training-view"><a href={"/client-dashboard/gut-training/details/" + this.gutid}>View Gut Training</a></span>

                <div className="row">
                    <h3><u>Add Products</u></h3>
                    <div className="col-sm-12 col-md-6">
                        <form onSubmit={this.addHydration}>
                            <div className="row">
                                <h4>What hydration products would you like to use?</h4>
                                <label htmlFor="session-type-hyd">Hydration</label>
                                <select name="session-type" ref={this.hydration} >
                                    <option value="no hydration">Select Product</option>

                                    {this.state.productInfo.map((result) => {
                                        if(result.type === 'hydration') {
                                            if(result.carbs !== '0') {
                                                return (<option
                                                    value={result.id}>{result.product_name} - {result.product_flavor}</option>)
                                            }else{
                                                if(this.state.current_products.length > 0){
                                                    return(<option value={result.id}>{result.product_name} - {result.product_flavor}</option>)
                                                }
                                            }
                                        }
                                    })}

                                </select>

                            </div>
                            <button onClick={() => this.toggleLoader()} className="new-session-submit">Add Hydration Product</button>
                        </form>
                        <div className="current-prod">
                            <h5>Current Hydration Products</h5>
                            <ul>
                                {this.state.current_products.map((result, index) => {
                                    if(result.type === 'hydration') {

                                        return (<li> {result.name} - {result.flavor}<a href="#" id={result.id} onClick={this.removeProduct} className="remove-product"> <FontAwesomeIcon icon={faCircleXmark} /></a> </li>)
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="prod-sep">
                    <hr></hr>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <form onSubmit={this.addFueling}>
                            <div className="row">
                                <h4>What fueling products would you like to use?</h4>

                                <label htmlFor="session-type-fuel">Fuel</label>
                                <select name="session-type-fuel" ref={this.fuel} >
                                    <option value="no hydration">Select Product</option>
                                    {this.state.productInfo.map((result) => {
                                        if(result.carbs !== '0') {
                                            return (<option
                                                value={result.id}>{result.product_name} - {result.product_flavor}</option>)
                                        }else{
                                            if(this.state.current_products.length > 0){
                                                return(<option value={result.id}>{result.product_name} - {result.product_flavor}</option>)
                                            }
                                        }
                                    })}

                                </select>
                            </div>
                            <button onClick={() => this.toggleLoader()} className="new-session-submit">Add Fueling Product</button>
                        </form>

                        <div className="current-prod">
                            <h5>Current Fueling Products</h5>
                            <ul>
                                {this.state.current_products.map((result, index) => {
                                    if(result.type === 'fuel') {

                                        return (<li> {result.name} - {result.flavor}<a href="#" id={result.id} onClick={this.removeProduct} className="remove-product"> <FontAwesomeIcon icon={faCircleXmark} /></a> </li>)

                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }


            </div>
                </div>
                <a href="https://nutritional-revolution.com/suggest-a-product/">Don't see your product?  Suggest one.</a>

            </div>
            </div>
        )
    }


}

export default withParams(GutDetails);
