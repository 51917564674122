import React, {Component} from 'react';
import { useSession } from '../../firebase/UserProvider';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/db";
import UserMetaForm from "../../functions/user-functions/addUserMeta";
import Navbar from "../../components/Navbar";
import { getApp } from "@firebase/app";
import {doc, getDoc, collection, getDocs, setDoc, addDoc, onSnapshot, query, where} from "firebase/firestore";
import getCustomClaimRole from "../../functions/user-functions/subsciption";
import { getStripePayments, getProducts } from "@invertase/firestore-stripe-payments";
const app = getApp();





class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display_name: '',
            email: '',
            photoURL: '',
            plans: [],
            customer_info:[],
            customer_active:[]
        }
        this.plans = [];
        this.getPriceID = React.createRef();
        this.unit_price = React.createRef();
        this.unit_id = React.createRef();
        this.sub_status = '';
        this.customer_sub = {};

        //this.userSubscription = this.userSubscription(this);
        //this.payment_submit = this.payment_submit.bind(this);
    }


    componentDidMount = async () => {
        console.log(getCustomClaimRole);

        const payments = getStripePayments(app, {
            productsCollection: "products",
            customersCollection: "customers",
        });

        const auth = getAuth();
        const user = auth.currentUser;

        console.log(user.email);

        const Stripe = require('stripe');
        const stripe = Stripe('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');

        const customers = await stripe.customers.search({
            query: 'email:\'' + user.email + '\'',
        });

        this.customer_sub = await stripe.customers.retrieve(
            customers.data[0].id,
            {
                expand: ['subscriptions']
            }
            )

        if(this.customer_sub.subscriptions.total_count > 0) {
            const sub_id = this.customer_sub.subscriptions.data[0].id;
            this.sub_status = this.customer_sub.subscriptions.data[0].status;


            const subscriptions = await stripe.subscriptions.retrieve(
                sub_id
            );

            const subscription_list = await stripe.subscriptions.list();
            this.setState({customer_active:subscription_list.status});
            console.log(subscription_list);
            this.state.customer_active=subscription_list.status;
        }

        const configuration = await stripe.billingPortal.configurations.create({
            features: {
                invoice_history: {
                    enabled: true,
                },
            },
        });

        const session = await stripe.billingPortal.sessions.create({
            customer: customers.data[0].id,
            return_url: 'https://sgt.nutritional-revolution.com/client-dashboard/account/',
        });

        this.session = session.url;


        const customerSession = await stripe.customerSessions.create({
            customer: customers.data[0].id,
            components: {
                buy_button: {
                    enabled: true,
                },
            },

        });

        this.client_secret = customerSession.client_secret;


        if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = user.displayName;
            this.setState({display_name: displayName});
            const email = user.email;
            this.setState({email: email})
            const photoURL = user.photoURL;
            this.setState({photoURL: photoURL})
            const emailVerified = user.emailVerified;
            const user_id = user.uid;


            // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
            const uid = user.uid;

            const customer_sub = await getDocs(collection(db, 'customers', user.uid, 'subscriptions'));

            customer_sub.forEach((sub) => {
                console.log(sub.data())

                console.log(sub.data().status)
            })

        }




    }


    render() {
        console.log(this.state.customer_active);
        if(this.state.customer_active === "active") {
            return (
                <div className="pageContainer">


                    <div className="myAccountContainer">
                        <div className="mainUser">
                            <p>Name : {this.state.display_name}</p>
                            <p>Email : {this.state.email}</p>
                            <p>Icon <img src=""></img></p>

                        </div>


                        <div className="pricing-plans-container row gx-5">

                            <div className="pricing-plans-single-container col">

                                <div className="pricing-plan-description">
                                    <stripe-buy-button
                                        buy-button-id="buy_btn_1QpZdtKzVJl3PF9qJtVGbIYf"
                                        publishable-key="pk_live_N1i4uXYZeDhdTxVOadj1Erkk"
                                        customer-session-client-secret={this.client_secret}
                                    >
                                    </stripe-buy-button>

                                </div>
                                <br/>

                            </div>
                            <div className="pricing-plans-single-container col">

                                <div className="pricing-plan-description">
                                    <stripe-buy-button
                                        buy-button-id="buy_btn_1QpZfEKzVJl3PF9qfMv3TB4d"
                                        publishable-key="pk_live_N1i4uXYZeDhdTxVOadj1Erkk"
                                        customer-session-client-secret={this.client_secret}
                                    >
                                    </stripe-buy-button>

                                </div>


                            </div>
                            <div>


                            </div>
                        </div>

                    </div>

                </div>
            )
        }else{
            return (
                <div className="pageContainer">
                    <Navbar/>

                    <div className="myAccountContainer">
                        <div className="mainUser">
                            <p>Name : {this.state.display_name}</p>
                            <p>Email : {this.state.email}</p>
                            <p>Icon <img src=""></img></p>

                        </div>
                        <div className="manage-subscription-container">
                            <p>You currently have a subscription with us.  Use the button below to upgrade, manage, or view invoicing of your subscription.</p>
                            <a href={this.session}>
                                <button value="Manage Subscription">Upgrade/Manage Subscription</button>
                            </a>
                        </div>

                        <div className="pricing-plans-container row gx-5">



                        </div>

                    </div>

                </div>
            )
        }
    }


}

export default Account;